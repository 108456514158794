<template>
  <el-row class="pagination">
    <el-col :span="24">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="page.current"
        :page-size="page.size"
        :page-count="page.last"
        @current-change="changePageCurrent"
        @size-change="changePageSize"
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>
<script>
import { ElPagination } from 'element-plus'
export default {
  components: {
    [ElPagination.name]: ElPagination
  },
  data () {
    return {
      cacheKey: '',
      cacheParam: '',
      url: '',
      param: '',
      page: { current: 1, size: 10, last: 100 }
    }
  },
  props: {
    last: Number,
    callback: Function
  },
  mounted () {
    this.cacheKey = this.$route.path
    var cache = window.sessionStorage.getItem(this.CACHE_PAGE)
    if (cache !== undefined && cache !== '' && cache !== null) {
      var cp = JSON.parse(cache)
      var ccp = cp[this.cacheKey]
      if (ccp !== undefined && ccp !== '') {
        this.cacheParam = JSON.parse(ccp)
      }
    }
  },
  methods: {
    searchPage (url, param) {
      this.url = url
      if (this.cacheParam !== '') {
        this.param = this.cacheParam
        this.page.current = this.cacheParam.current
        this.page.size = this.cacheParam.size
        this.cacheParam = ''
      } else {
        this.param = param
      }
      console.log(this.cacheParam)
      this.searchExecute()
    },
    cusSearchPage (url, param, page) {
      if (page.size !== undefined) {
        this.page.size = page.size
      }
      this.searchPage(url, param)
    },
    changePageCurrent (current) {
      this.page.current = current
      this.searchExecute()
    },
    changePageSize (size) {
      this.page.size = size
      this.searchExecute()
    },
    async searchExecute () {
      const loading = this.$loading(this.LOADING_OPTION)
      var sp = this.param
      sp.current = this.page.current
      sp.size = this.page.size
      const { data: res } = await this.$http.post(this.url, this.$qs.stringify(sp))
      if (res.status === '200') {
        this.page.last = res.page.last
        var ins = {}
        ins[this.cacheKey] = JSON.stringify(sp)
        window.sessionStorage.setItem(this.CACHE_PAGE, JSON.stringify(ins))
        this.callback(res.data, this.param)
      }
      loading.close()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pagination {
  text-align: right;
}
</style>
